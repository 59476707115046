import type { AppProps } from 'next/app';
import { api } from '~/utils/api';
import "~/styles/globals.css";
import "swagger-ui-react/swagger-ui.css";
import "~/styles/swagger-theme-dracula.css";

function MyApp({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />;
}

export default api.withTRPC(MyApp);
